import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

// Components
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Intro from "../components/Intro"
import Button from "../components/Button"
import ProgressTracker from "../components/ProgressTracker"
import PageNavigation from "../components/PageNavigation"

const ThankYouPage = () => {
  return (
    <Layout>
      <SEO title="Thank you" />
      <ProgressTracker currentStep={4} />
      <Intro
        title="That was all!"
        text={[
          "Thank you for your time and sharing your thoughts! Interested in knowing more about the Dutch National Design System? Come to the Hack Space (2nd floor, zaal 11)!",
          "Follow the real-time results of this survey here: ",
        ]}
      />
      <PageNavigation
        label="See the results"
        from="/details"
        to="/results"
        backLabel="Start over"
      />
    </Layout>
  )
}

export default ThankYouPage
